import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {Link} from 'react-router-dom';
import "./App.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, runTransaction, onValue } from 'firebase/database';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAKqCPlAZxdcH0o2qMSwFlB3OBorOU6xtA",
  authDomain: "trillioncount.firebaseapp.com",
  databaseURL: "https://trillioncount-default-rtdb.asia-southeast1.firebasedatabase.app/",
  projectId: "trillioncount",
  storageBucket: "trillioncount.appspot.com",
  messagingSenderId: "279124549000",
  appId: "1:279124549000:web:6ee0485fbc4115d781ae3d",
  measurementId: "G-SV8LKE75GW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const clicksRef = ref(database, 'clicks');
const clickBRef = ref(database, 'clickB');
const clickARef = ref(database, 'clickA');
const ARef = ref(database, 'A');
const BRef = ref(database, 'B');
const liveRef = ref(database, 'live');
const contributorsRef = ref(database, 'contributors');
const maxClickRef = ref(database, 'maxClicks');
const quesRef = ref(database, 'ques');
// const messageRef = ref(database, 'message');
const limitRef = ref(database, 'limit');
function App() {
  const [count, setCount] = useState("Loading...");
  const [clickcount, setClickcount] = useState(0);
  const [contributors, setcontributors] = useState("Loading...");
  const [maxClicks, setmaxClicks] = useState("Loading...");
  const [clickA, setclickA] = useState(0);
  const [clickB, setclickB] = useState(0);
  const [Acount, setAcount] = useState("Loading...");
  const [Bcount, setBcount] = useState("Loading...");
  const [A, setA] = useState("Loading...");
  const [B, setB] = useState("Loading...");
  const [ques, setques] = useState("Loading...")
  // const [message, setmessage] = useState("");
  // const [topMessage, settopMessage] = useState("");
  const [limit, setlimit] = useState("Loading");
  const [live, setlive] = useState(false);
  const handleIncrement = () => {
    if(clickA == 0 && clickB == 0)return;
    const clickToAdd = (clickA>clickB?clickA:clickB);
    if(clickToAdd>limit){
      toast.error(`Maximum limit for clicks for now is ${limit}. Limits will increase once this record is broken.`,{
        position: 'top-right',
        autoClose: 3000, // Duration in milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    
    runTransaction(clicksRef, (currentData) => {
      
      const newCount = (currentData || 0) + clickToAdd;
      setCount(newCount);
      
      return newCount;
    });
    if(clickA>clickB){
      runTransaction(clickARef, (currentData) => {
        const newCount = (currentData || 0)+clickToAdd;
        setAcount(newCount);
        return newCount;
      })
    }else{
      runTransaction(clickBRef, (currentData) => {
        const newCount = (currentData || 0)+clickToAdd;
        setBcount(newCount);
        return newCount;
      })
    }
    increaseContributors();
    if(clickcount>maxClicks){
      changeMax();
    }
    setClickcount(0);
    setclickA(0);
    setclickB(0);
    
  };
  const changeMax = () => {
    runTransaction(maxClickRef, (currentData) => {
      const newCount = clickcount;
      setmaxClicks(newCount);
      
      return newCount;
    });
    // runTransaction(messageRef, (currentData) => {
    //   settopMessage(message);
      
    //   return message;
    // });
  }
  const increaseContributors = () =>{
     runTransaction(contributorsRef, (currentData) => {
      const newCount = (currentData || 0) + 1;
      setcontributors(newCount);
      
      return newCount;
    });
  }

  
  useEffect(() => {
    const fetchData = () => {
      // Fetch both clicks and contributors
      onValue(liveRef, (liveSnapshot) => {
        const islive = liveSnapshot.val();
        setlive(islive);
      });
      onValue(clicksRef, (clicksSnapshot) => {
        const clicks = clicksSnapshot.val();
        setCount(clicks);
      });
      onValue(clickARef, (clicksSnapshot) => {
        const clicks = clicksSnapshot.val();
        setAcount(clicks);
      });
      onValue(clickBRef, (clicksSnapshot) => {
        const clicks = clicksSnapshot.val();
        setBcount(clicks);
      });
      onValue(ARef, (ASnapshot) => {
        const a = ASnapshot.val();
        setA(a);
      });
      onValue(BRef, (BSnapshot) => {
        const b = BSnapshot.val();
        setB(b);
      });
      onValue(quesRef, (quesSnapshot) => {
        const b = quesSnapshot.val();
        setques(b);
      });
  
      onValue(contributorsRef, (contributorsSnapshot) => {
        const contributorsCount = contributorsSnapshot.val();
        setcontributors(contributorsCount);
      });
      onValue(maxClickRef, (maxClickSnapshot) => {
        const maxClick = maxClickSnapshot.val();
        setmaxClicks(maxClick);
      });
      // onValue(messageRef, (messageSnapshot) => {
      //   const readMessage = messageSnapshot.val();
      //   settopMessage(readMessage);
      // });
      onValue(limitRef, (limitSnapshot) => {
        const limitVal = limitSnapshot.val();
        setlimit(limitVal);
      });
    };
  
    fetchData();
  }, []);
  const handleLinkClick = () => {
    // Open the link in a new tab
    window.open('https://dmyor.com/s/65a389ea928e91c3ab4e7b87', '_blank');
  };
  const handleClickA = (e) => {
    if(clickA+1>limit){
      console.log("yoyo");
      toast.error(`Maximum limit for clicks for now is ${limit}. Limits will increase once this record is broken.`,{
        position: 'top-right',
        autoClose: 3000, // Duration in milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    setclickA(clickA+1);
  }
  const handleClickB = (e) => {
    if(clickB+1>limit){
      console.log("yoyo");
      toast.error(`Maximum limit for clicks for now is ${limit}. Limits will increase once this record is broken.`,{
        position: 'top-right',
        autoClose: 3000, // Duration in milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    setclickB(clickB+1);
  }
  const handleClick = (e) => {
    if(clickcount+1>limit){
      console.log("yoyo");
      toast.error(`Maximum limit for clicks for now is ${limit}. Limits will increase once this record is broken.`,{
        position: 'top-right',
        autoClose: 3000, // Duration in milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    setClickcount(clickcount+1);
  }
  return (
    <>
    <div className="App">
      {/* <Link to = "https://dmyor.com/s/65a389ea928e91c3ab4e7b87"> */}
      <button className = "submit" onClick={handleLinkClick}>Dmyor node</button>
      {/* </Link> */}
      {/* <p>Our top contributor says: <br/><span>{topMessage}</span></p> */}
      <div className='circle'>
        <div>
        <p>{count}</p>
        <hr/>
        <p>1B</p>
        </div>
      </div>
      
      <p className='click'>Live scores: {A} <span className={`${Bcount<Acount?'green':'red'}`}>{Acount}</span> {B} <span className={`${Bcount>Acount?'green':'red'}`}>{Bcount}</span></p>
      {!live?(<p className='warning'>This event has ended. Clicking is not possible. New question will be posted soon.</p>):(<></>)}
      <p className='click'>{ques}</p>
      {live?(
        <>
      
      <div>
        <p className='click'>{clickA>clickB?clickA:clickB}</p>
      <div className="buttons">
      <button value={clickcount} onClick={(e) =>handleClickA(e)}>{A}</button>
      <button value={clickcount} onClick={(e) =>handleClickB(e)}>{B}</button>
      </div>
      
      {/* <button value={clickcount} onClick={(e) =>handleClick(e)}>Click here</button> */}
      {/* <p>If you become top contributor, your message will be displayed!</p> */}
      {/* <input 
      type="text" 
      placeholder="Your message..."
      value={message}
      onChange={(e)=>setmessage(e.target.value)} 
      />  */}
      <button className = "submit" onClick={handleIncrement}>Submit</button>
      </div>
      </>
      ):(<>
      <div className="winner">
        <p>Answer: {Acount>Bcount? A:B}</p>
      </div>
      </>)}
      {/* <div>
        <p className = "stats">Contributors: {contributors}</p>
        <p className = "stats">Record contribution: {maxClicks}</p>
      </div> */}
      <footer>
        <div className="footer-links">
          
          <a href="https://www.instagram.com/becauseidmyor/" target="_blank" rel="noopener noreferrer">
            Instagram
          </a>
          <span className="separator">|</span>
          <a href="https://dmyor.com/" target="_blank" rel="noopener noreferrer">
            Dmyor
          </a>
        </div>
      </footer>
    </div>
    </>
  );
}

export default App;